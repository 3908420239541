import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { HeadingLevel } from '@aurora/shared-client/types/enums';
import { LastActivityTimePrependText } from '../../../../types/enums';
import { NodeCardSize } from '@aurora/shared-types/nodes/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import type { NodeViewCardProps } from '../types';

const defaultViewProps: Partial<NodeViewCardProps> = {
  nodeIconSize: IconSize.PX_160,
  useNodeAvatar: true,
  useNodeTitle: true,
  useNodeDescription: true,
  useNodeLatestActivityTime: true,
  useNodeMembersCount: true,
  useNodeMembersCountText: false,
  useNodeMembershipType: { useNodeType: false, hideOpen: true },
  useNodeTopicsCount: true,
  useNodeOwners: false,
  useNodeCreationDate: false,
  useClickableCard: true,
  useNodeAction: false,
  useNodeFollowersCount: false,
  useNodePostCount: false,
  useUserHoverCard: true,
  useUnreadMessagesCount: false,
  textKey: LastActivityTimePrependText.LATEST_ACTIVITY,
  useTextDescription: true,
  useChildNodes: false,
  nodeCardSize: NodeCardSize.LG,
  textAlignment: TextAlignment.CENTER,
  isNestedNode: false,
  titleAs: HeadingLevel.H4
};

export default defaultViewProps;
