import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import { HeadingLevel } from '@aurora/shared-client/types/enums';
import { MessageTimestamp, ViewSize } from '../../../../types/enums';
import type { MessageViewInlineProps } from '../types';

const defaultViewProps: MessageViewInlineProps = {
  useAvatar: false,
  avatarSize: IconSize.PX_40,
  useBoardIcon: true,
  boardIconSize: IconSize.PX_30,
  useSearchSnippet: false,
  useAuthorLogin: true,
  useAuthorLoginLink: true,
  useAuthorRank: false,
  useNode: true,
  useNodeLink: true,
  useMessageTimeLink: false,
  useSubject: true,
  useSubjectLink: true,
  useBody: true,
  useReadOnlyIcon: true,
  useMedia: true,
  usePreviewMedia: true,
  useRepliesCount: true,
  useSolvedBadge: true,
  useTags: true,
  useFooterDivider: false,
  useAttachments: false,
  useIntroduction: false,
  clampBodyLines: 2,
  useKudosCount: true,
  useShortTitle: false,
  subjectAs: HeadingLevel.H4,
  viewSize: ViewSize.MD,
  useCompactSpacing: false,
  renderPostTimeBeforeAuthor: false,
  useViewCount: false,
  useUnreadCount: false,
  useTimeToRead: false,
  timeStampType: MessageTimestamp.POST_TIME,
  useUserHoverCard: true,
  useNodeHoverCard: true,
  useTeaser: true,
  useWorkflowState: false,
  useWorkflowActionMenu: false,
  useModerationActionMenu: false,
  useLastUpdateDateText: false,
  useContributors: false,
  useDraftView: false,
  useRejectedStatus: false,
  usePublishStatus: false,
  useModerator: false,
  openLinksInNewTab: false,
  useVideoPreview: true,
  useOccasionTime: false,
  useOccasionLocation: false,
  useOccasionDateIcon: false,
  useVoteComponent: false,
  useAttendeeCount: false,
  useAttendanceIndicator: false,
  useMessageStatus: false,
  useMessageLink: false,
  useOccasionType: false,
  useOccasionBadge: false,
  useEntireItemAsLink: false,
  useManualSortNav: false
};

export default defaultViewProps;
